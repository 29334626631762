import CaptionedImage from "../../../../src/components/CaptionedImage";
import Instabug from "../../../../src/components/Instabug";
import FlowGrid from "../../../../src/components/FlowGrid";
import IOS13SystemColorTable from "../../../../src/components/IOS13SystemColorTable";
import React from 'react';
export default {
  CaptionedImage,
  Instabug,
  FlowGrid,
  IOS13SystemColorTable,
  React
};